export function moveToFront(value, array) {
	const newArr = array.filter((inArray)=> inArray !== value);
	const diff = array.length - newArr.length;
	return Array(diff).fill(value).concat(newArr);
}

export function getRenderState(reactState) {
	return reactState[0];
}

export function getState(reactState) {
	let ret;
	reactState[1]((oldVal) => {ret = oldVal; return oldVal;});
	return ret;
}

export function setState(reactState, newVal) {
	return reactState[1](newVal);
}
////

export function deepEqual(a,b) {
	if (a === b) {
		return true;
	}
	if (typeof a !== typeof b ) {
		return false;
	}
	if(typeof a === "object" || typeof a === "function") {
		if( a === null || b === null) {
			return false;
		}
		let [ak,bk] = [Object.keys(a), Object.keys(b)];
		if (a.length !== b.length){
			return false;
		}
		ak.sort();
		bk.sort();
		for(let i in ak) {
			if(ak[i] != bk[i]) {
				return false;
			}
		}
		return ak.every(k => deepEqual(a[k],b[k]));
	}
		
	return false;
}

////

function processCsvLine(str) {
	let result = [];
	const len = str.length;
	let start = 0;
	for(let end = 0; end < len;end++) {
		let char = str.charAt(end);
		if(start === end && char === "\"") {
			for(end = end+1;end < len;end++) {
				if(str.charAt(end) === "\"" && (end+1 === len || str.charAt(end+1) === ",")) {
					//if got second quote and the next character is a comma or the end of the line
					let newStr = str.substring(start+1,end);
					newStr = newStr.replaceAll("\\\"","\"");
					result.push(newStr);
					start = ++end+1;
					break;
				}
			}
		} else if(char === ",") {
			const newStr = str.substring(start,end);
			result.push(newStr);
			start = end+1;
		}
	}
	if(start < len) {
		const newStr = str.substring(start,len);
		result.push(newStr);
	}
	return result;
}

export function generateCsv(report) {
	let csv = report.csvHeaders.join(",");
	for(let row of report.csvData) {
		csv += "\n" + row.join(",");
	}
	return csv;
}

export function emptyCsv() {
	return {
		csvHeaders: [],
		csvData: [],
		raw: "",
	};
}

export function readCsv(raw) {
	
	const lines = raw.split("\n");
	const csvHeaders = processCsvLine(lines[0]);
	const csvData = lines.filter((_,i)=>i > 0).map(processCsvLine);

	return {csvHeaders, csvData, raw};
}

export function downloadFile(blob, filename) {
	if(window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveBlob(blob, filename);
	}
	else{
		const elem = window.document.createElement("a");
		elem.href = window.URL.createObjectURL(blob);
		elem.download = filename;        
		document.body.appendChild(elem);
		elem.click();        
		document.body.removeChild(elem);
	}
}